import "@babel/register";
//import "@babel/polyfill";

import style from "../style/style";

if (!global.ntdl) {
  global.ntdl = {};
}

(() => {
  global.ntdl.isIE = (() => {
    // We only support IE 11 in the IE range
    const ie = window.navigator.userAgent.indexOf("Trident/");
    return ie >= 0;
  })();

  /* This event listener is really only useful if the page document is scrolling */
  /* The app has been modified so that the body does not scroll, and results are */
  /* displayed in a <div> element where the *parent* is the scrollable element.  */
  /* Scroll Monitor is now managed in results.jsx, or in the mapping component.  */
  //  (() => {
  //    const callbacks = [];
  //
  //    document.addEventListener('scroll', () => {
  //      const scroll_top = window.scrollY || window.pageYOffset;
  //      const scroll_bottom = scroll_top + window.innerHeight;
  //      const from_bottom = document.documentElement.offsetHeight - scroll_bottom;
  //
  //      for (let callback of callbacks) {
  //        callback({scroll_top, scroll_bottom, from_bottom});
  //      }
  //    });
  //
  //    /**
  //     * Monitor scroll events
  //     * @param callback {function} Perform a callback when a scroll has occurred
  //     */
  //    global.ntdl.scroll_monitor = (callback) => {
  //      callbacks.push(callback);
  //    };
  //
  //    global.ntdl.remove_scroll_monitor = callback => {
  //      callbacks.remove(callback);
  //    };
  //  })();

  /**
   * Scroll the page
   * @param destination {number} the y position
   * @param duration {number} number of milliseconds to perform scroll
   * @param element {object} the element to scroll (optional - body if unspecified)
   */
  global.ntdl.scroll = (destination, duration, element) => {
      /**
       * Ease in and out quadratic
       * @param t {number} current time
       * @param b {number} beginning value
       * @param c {number} change In value
       * @param d {number} duration
       * @return value at time
       * @author George McGinley Smith
       * @license BSD license
       */
      const ease = (t, b, c, d) => {
    		if ((t/=d/2) < 1) {
          return c/2*t*t + b;
        }

    		return -c/2 * ((--t)*(t-2) - 1) + b;
    	};

      if (!element) {
        element = document.body;
      }

      const start = element.scrollTop;
      const change = destination - start;
      const start_time = new Date().getTime();

      const scroll = () => {
        const time = Math.min(new Date().getTime() - start_time, duration);
        element.scrollTop = ease(time, start, change, duration);
        if (time >= duration) {
          return;
        }

        setTimeout(scroll, 20);
      }

      scroll();
  }

  /**
   * Run an asynchronous Ajax command
   * @param method {string} HTTP verb
   * @param url {string} the url to send the ajax request to
   * @param data {object} data to send (optional)
   * @return Promise for ajax result
   * @note if running in Django you will need to add a window.csrf token
   */
  global.ntdl.ajax = function(method, url, data) {
    return new Promise((accept, _reject) => {
      const reject = (status, text) => _reject({status, text});
      const request = new XMLHttpRequest();
      request.open(method, url, true);
      request.setRequestHeader("Content-Type", "application/json");
      // written out in full because Atom made // in to a comment.
      const isExternal = new RegExp("^https?:\/\/").test(url);
      if (window.csrf && !isExternal) {
        request.setRequestHeader('X-CSRFToken', window.csrf);
      }
      if (data) {
        request.send(JSON.stringify(data));
      } else {
        request.send();
      }

      request.onload = () => {
        if (request.status >= 200 && request.status < 400) {
          try {
            accept(JSON.parse(request.responseText));
          } catch (e) {
            reject(request.status, 'failed to parse JSON');
          }
        } else {
          reject(request.status, `bad response code (${request.status})`);
        }
      };

      request.onerror = () => {
         reject(0, 'failed to connect');
      }
    });
  }
})();

Array.prototype.remove = function(key) {
  const index = this.indexOf(key);
  if (index < 0) {
    return false;
  }

  this.splice(index, 1);
  return true;
}
