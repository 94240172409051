if (!global.ntdl) {
  global.ntdl = {};
}

function debounce(callback, context = this, wait = 100) {
  let timeout = null
  let callbackArgs = null

  const later = () => callback.apply(context, callbackArgs);

  return function() {
    callbackArgs = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }
}

const autocompleteScreen = document.getElementById('autocomplete-screen');
const autocompleteResults = document.getElementById('autocomplete-results');
const searchQuery = document.getElementById('search-input');

let autoCompleteShow = false;

const _autocomplete = async (e) => {

  var searchInputValue = searchQuery.value.trim();

  if (searchInputValue.length == 0) {
     return;
  }
	
  const query = e ? e.target.value.trim() : undefined;
  // 9 = tab, 13 = enter, 32 = space
  autoCompleteShow = query && e.keyCode !== 9 && e.keyCode !== 13 && e.keyCode !== 32 ;

  if (!autoCompleteShow) {
    autoCompleteShow = false;
    autocompleteScreen.classList.remove('show');
    return;
  }

  let result;
  try {
    result = await global.ntdl.ajax('get', '/api/autocomplete?query=' + encodeURIComponent(query));
  } catch(e) {
    console.log(`AJAX Failed: ${e.text || e}`);
    return;
  }

  if (!autoCompleteShow) {
    return;
  }

  if (result && (!result.matches || result.matches.length === 0)) {
    autocompleteScreen.classList.remove('show');
    return;
  }

  autocompleteResults.innerHTML = result.matches.map(i => {
    // This used to perform a search but it clears the facets:
    //const url = `${window.search_path}?query=${encodeURIComponent(i)}`;
    //return `<li><a href="${url}">${i}</a></li>`;
    // Now we just update the search with the value from the autocomplete:
    return `<li><div class="clickable" onclick="document.getElementById('search-input').value = '${i}'; document.getElementById('search-input').focus();">${i}</div></li>`;
  }).join('');

  autocompleteScreen.classList.add('show');
};

(() => {

  global.ntdl.ready = (callback) => {
    if (document.readyState != 'loading'){
      callback();
    } else {
      document.addEventListener('DOMContentLoaded', callback);
    }
  };

  // Set custom error message if a field is not valid.
  const fieldValidityMessage = (field, message) => {
    field.addEventListener('invalid', (e) => {
      e.target.setCustomValidity('');
      if (!e.target.validity.valid) {
        e.target.setCustomValidity(message);
      }
    });

    field.addEventListener('input', (e) => {
      e.target.setCustomValidity('');
    });
  };




  const search = () => {
    const searchButton = document.getElementById('toggle-search-button');
    const searchQuery = document.getElementById('search-input');
    const navbarClear = document.getElementById('navbar-clear');


    const autocomplete = debounce(_autocomplete);
    searchQuery.addEventListener('keyup', e => autocomplete(e));
    searchQuery.addEventListener('onblur', () => autocomplete());
    autocompleteScreen.addEventListener('click', () => autocomplete());
    navbarClear.addEventListener('click', () => autocomplete());
  };

  window.ntdl.ready(() => {
    search();

    // Stop users accidently dragging images. The images are downloadable in
    // full quality. So they have no need to do this.
    document.querySelector('body').ondragstart = () => {
      return false;
    };
  });
})();
